import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`I hate flying blind, so i made a ROAS calculator`}</h1>
    <p>{`I hate flying blind.`}</p>
    <p>{`That’s my lingo for doing business without knowing my numbers.`}</p>
    <p>{`Working on the ecom store, I feel like I’m flying blind.`}</p>
    <p>{`How much is each order worth? How much does it cost? How much do these little packaging things cost? How much does it cost to acquire a user?`}</p>
    <p>{`So I spent an hour today making a simple `}<strong parentName="p">{`Ecommerce Target ROAS Calculator.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://docs.google.com/spreadsheets/d/10EVvwYUbyQV7u8w87vQql6DBV8lze9fprHc98nU3-xs/edit#gid=0"
      }}><strong parentName="a">{`Check it out`}</strong></a>{` and feel free to make a copy for yourself.`}</p>
    <p><strong parentName="p">{`ROAS`}</strong>{` stands for `}<strong parentName="p">{`R`}</strong>{`eturn `}<strong parentName="p">{`o`}</strong>{`n `}<strong parentName="p">{`A`}</strong>{`d `}<strong parentName="p">{`S`}</strong>{`pend. It’s like ROI, but for advertising.`}</p>
    <p>{`This calculator is a little confusing - but lemme simplify it:`}</p>
    <p>{`It answers this question: “for every $1 i spend in marketing, how many dollars of revenue do I need back to breakeven, once you take into account the cost of goods sold?”`}</p>
    <p>{`My answer came out to 1.84x.`}</p>
    <p>{`For every order, it costs us $0.84 on the cost of goods, shipping, discounts etc to get the product into customers hands.. So for every $1 I spend in marketing, I need to make back $1.84 to breakeven overall.`}</p>
    <p><strong parentName="p">{`I share this for two reasons:`}</strong><br parentName="p"></br>{`
`}{`#1 - this is super handy if you’re doing an ecommerce project, make a copy of it`}<br parentName="p"></br>{`
`}{`#2 - this is how my brain works. I jump in blind, then immediately try to get clarity on the numbers. These types of calculators take ~1-2 hours to make, but they save months of pain if you do it up front.`}</p>
    <p>{`I have a bunch of these little calculators handy. They aren’t perfectly accurate or super detailed - that’s not the point. I’m not looking for exact science. Simplicity over Perfection.`}</p>
    <p><strong parentName="p">{`If you like little tools like this, let me know and I can do more posts like this in the future.`}</strong></p>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/spreadsheets/d/10EVvwYUbyQV7u8w87vQql6DBV8lze9fprHc98nU3-xs/edit#gid=0',
        label: 'Ecommerce Target ROAS Calculator'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.the_more_i_know_about_facebook_ads_the_better.label,
      slug: slugify(postMap.the_more_i_know_about_facebook_ads_the_better.slug)
    }} nextButton={{
      text: postMap.we_got_our_sample.label,
      slug: slugify(postMap.we_got_our_sample.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      